var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ts-page-title',{attrs:{"title":_vm.$t('monthlyDeduction.pageTitle'),"breadcrumb":[
            { text: _vm.$t('home'), href: '/' },
            {
                text: _vm.$t('monthlyDeduction.pageTitle'),
                active: true
            }
        ]}}),_c('ts-panel',[_c('ts-panel-wrapper',{staticClass:"tw-flex tw-items-center tw-justify-between"},[_c('div',{staticClass:"tw-space-x-2"},[_c('Button',{attrs:{"type":"info","ghost":""},on:{"click":_vm.addNew}},[_vm._v(_vm._s(_vm.$t("addNew")))]),_c('div',{staticClass:"btn-group"},[_c('Dropdown',{staticClass:"btn btn-default",attrs:{"trigger":"click"}},[_c('a',[_vm._v(" Import "),_c('Icon',{attrs:{"type":"ios-arrow-down"}})],1),_c('DropdownMenu',{attrs:{"slot":"list"},slot:"list"},_vm._l((_vm.payItemDeductions),function(item,index){return _c('DropdownItem',{key:index,staticClass:"tw-text-left"},[_c('a',{on:{"click":function($event){$event.preventDefault();return _vm.onImportDeduction(item)}}},[_vm._v(_vm._s(item.deduction_item))])])}),1)],1)],1),_c('ts-button',{attrs:{"color":"success"},on:{"click":function($event){$event.preventDefault();return _vm.clearInstallment.apply(null, arguments)}}},[_vm._v(_vm._s(_vm.$t("monthlyDeduction.clearInstallment")))])],1),_c('div',{staticClass:"tw-flex tw-space-x-2"},[_c('div',[_c('DatePicker',{attrs:{"type":"year","format":"yyyy","placeholder":"Select Year","value":_vm.year},on:{"on-change":_vm.onChangeYear}})],1),_c('div',[_c('DatePicker',{attrs:{"type":"month","format":"MM","placeholder":"Select Month","value":_vm.month},on:{"on-change":_vm.onChangeMonth}})],1),_c('div',[_c('Input',{attrs:{"search":"","placeholder":_vm.$t('monthlyDeduction.searchEmployee')},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)])]),_c('Table',{attrs:{"columns":_vm.columns,"data":_vm.resources,"size":"small","loading":_vm.loading,"max-height":"700","stripe":""},scopedSlots:_vm._u([{key:"card_id",fn:function(ref){
        var row = ref.row;
return [_vm._v(" "+_vm._s(row.employee_profile ? row.employee_profile.card_id : "")+" ")]}},{key:"employee_name_en",fn:function(ref){
        var row = ref.row;
return [_vm._v(" "+_vm._s(row.employee_profile ? _vm.$root.$i18n.locale === "kh" ? row.employee_profile.employee_name_kh : row.employee_profile.employee_name_en : "")+" ")]}},{key:"sex",fn:function(ref){
        var row = ref.row;
return [_vm._v(" "+_vm._s(row.employee_profile ? row.employee_profile.sex : "")+" ")]}},{key:"position_name",fn:function(ref){
        var row = ref.row;
return [_vm._v(" "+_vm._s(row.employee_profile ? row.employee_profile.logical_position ? row.employee_profile.logical_position .position_name_en : "" : "")+" ")]}},{key:"deduction_item",fn:function(ref){
        var row = ref.row;
return [_vm._v(" "+_vm._s(row.pay_item_deduction ? row.pay_item_deduction.deduction_item : "")+" ")]}},{key:"deduction",fn:function(ref){
        var row = ref.row;
return [_c('span',{staticClass:"tw-font-bold text-blue"},[_vm._v(_vm._s(_vm.formatNumber(row.deduction)))])]}},{key:"is_locked",fn:function(ref){
        var row = ref.row;
return [(row.is_locked)?_c('span',{staticClass:"badge bg-success"},[_vm._v(_vm._s(_vm.$t("yes")))]):_c('span',{staticClass:"badge bg-secondary"},[_vm._v(_vm._s(_vm.$t("no")))])]}},{key:"action",fn:function(ref){
        var row = ref.row;
return [_c('a',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.$t('edit')),expression:"$t('edit')"}],staticClass:"text-primary ml-2",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.onEdit(row)}}},[_c('Icon',{attrs:{"type":"ios-create","size":"20"}})],1),_c('Poptip',{attrs:{"confirm":"","title":_vm.$t('areYouSure'),"transfer":true,"ok-text":_vm.$t('yes'),"cancel-text":_vm.$t('no')},on:{"on-ok":function($event){return _vm.onDelete(row)}}},[_c('a',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.$t('delete')),expression:"$t('delete')"}],staticClass:"ml-2 text-danger",attrs:{"disabled":row._deleting}},[(row._deleting)?_c('i',{staticClass:"fas fa-circle-notch fa-spin"}):_c('Icon',{attrs:{"type":"ios-trash","size":"20"}})],1)])]}}])}),_c('div',{staticClass:"tw-flex tw-items-center tw-justify-end tw-p-4"},[_c('ts-i-pagination',{on:{"navigate":function($event){return _vm.fetchData({
                    filter: {
                        search: _vm.search,
                        cycle_year: _vm.year,
                        cycle_month: _vm.month
                    }
                })}},model:{value:(_vm.pagination),callback:function ($$v) {_vm.pagination=$$v},expression:"pagination"}})],1),_c('Modal',{attrs:{"draggable":"","sticky":"","scrollable":"","mask":false,"footer-hide":true,"z-index":1022,"width":"700px","title":_vm.$t('monthlyDeduction.pageTitle')},model:{value:(_vm.showForm),callback:function ($$v) {_vm.showForm=$$v},expression:"showForm"}},[_c('from-action',{ref:"form_action",on:{"cancel":_vm.clearEdit,"fetchData":function($event){return _vm.fetchData({
                    filter: {
                        search: _vm.search,
                        cycle_year: _vm.year,
                        cycle_month: _vm.month
                    }
                })}}})],1),_c('Modal',{attrs:{"draggable":"","sticky":"","scrollable":"","mask":false,"footer-hide":true,"z-index":1052,"width":"1200px","title":_vm.$t('monthlyDeduction.importUpadidLeave')},model:{value:(_vm.showUnpaidLeave),callback:function ($$v) {_vm.showUnpaidLeave=$$v},expression:"showUnpaidLeave"}},[_c('unpaid-leave',{ref:"unpaid_leave",on:{"importSuccess":_vm.unpaidLeaveSuccess}})],1),_c('Modal',{attrs:{"draggable":"","sticky":"","scrollable":"","mask":false,"footer-hide":true,"z-index":1052,"width":"1200px","title":_vm.$t('monthlyDeduction.importInstallment')},model:{value:(_vm.showInstallment),callback:function ($$v) {_vm.showInstallment=$$v},expression:"showInstallment"}},[_c('installment',{ref:"installment",on:{"importSuccess":_vm.loanInstallmentSuccess}})],1),_c('Modal',{attrs:{"draggable":"","sticky":"","scrollable":"","mask":false,"footer-hide":true,"z-index":1052,"width":"1200px","title":_vm.$t('monthlyDeduction.clearLoanInstallment')},model:{value:(_vm.clearInstallmentForm),callback:function ($$v) {_vm.clearInstallmentForm=$$v},expression:"clearInstallmentForm"}},[_c('clear-installment',{ref:"clear_Installment",on:{"clearSuccess":_vm.clearLoanInstallmentSuccess,"close":function () { return (_vm.clearInstallmentForm = false); }}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }