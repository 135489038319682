var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"mb-3 demo-spin-article"},[_c('ts-panel-wrapper',{staticClass:"tw-flex tw-items-center tw-justify-between"},[_c('div',{staticClass:" tw-flex tw-ml-12"},[_c('div',{staticClass:"tw-space-x-4"},[_c('label',{staticClass:"col-label form-label tw-text-xs"},[_vm._v(_vm._s(_vm.$t('monthlyDeduction.year')))]),_c('date-Picker',{class:{
                            'is-invalid': _vm.errors.has('cycle_year')
                        },staticStyle:{"width":"320px"},attrs:{"value":_vm.model.cycle_year,"type":"year","format":"yyyy","placeholder":"Select Year"},on:{"on-change":_vm.cycleYearChange}}),(_vm.errors.has('cycle_year'))?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(_vm.errors.first('cycle_year'))+" ")]):_vm._e()],1),_c('div',{staticClass:" tw-space-x-4"},[_c('label',{staticClass:"col-label form-label tw-text-xs tw-ml-12"},[_vm._v(_vm._s(_vm.$t('monthlyDeduction.month')))]),_c('DatePicker',{class:{
                            'is-invalid': _vm.errors.has('cycle_month')
                        },staticStyle:{"width":"320px"},attrs:{"value":_vm.model.cycle_month,"type":"month","placeholder":"Select Month","format":"MM"},on:{"on-change":_vm.cycleMonthChange}}),(_vm.errors.has('cycle_month'))?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(_vm.errors.first('cycle_month'))+" ")]):_vm._e()],1)]),_c('button',{staticClass:"btn ts-whitespace-no-wrap btn-default btn-primary mr-2",attrs:{"type":"button","waiting":_vm.waiting,"disabled":_vm.waiting_new || _vm.waiting},on:{"click":function($event){$event.preventDefault();return _vm.onGenerate.apply(null, arguments)}}},[_vm._v(" Submit ")])]),_c('div',{staticClass:"mb-3"},[_c('Input',{attrs:{"search":"","placeholder":_vm.$t('monthlyDeduction.search')},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('Table',{ref:"selection",attrs:{"highlight-row":"","columns":_vm.columns,"data":_vm.employees,"stripe":""},on:{"on-select":_vm.setEmployeeIdListSelected,"on-select-cancel":_vm.setEmployeeIdListSelected,"on-select-all":_vm.setEmployeeIdListSelected,"on-select-all-cancel":_vm.setEmployeeIdListSelected},scopedSlots:_vm._u([{key:"photo",fn:function(ref){
                        var row = ref.row;
return [_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:({
                    src: _vm.photo(row.employee),
                    error:
                        row.employee.sex == 'Male'
                            ? require('@/assets/staff-default-man.png')
                            : require('@/assets/staff-default-women.png'),
                    loading: require('@/assets/Rolling-1s-200px.svg'),
                    preLoad: 1.3,
                    attempt: 1
                }),expression:"{\n                    src: photo(row.employee),\n                    error:\n                        row.employee.sex == 'Male'\n                            ? require('@/assets/staff-default-man.png')\n                            : require('@/assets/staff-default-women.png'),\n                    loading: require('@/assets/Rolling-1s-200px.svg'),\n                    preLoad: 1.3,\n                    attempt: 1\n                }"}],staticClass:"tw-rounded tw-h-8"})]}},{key:"card_id",fn:function(ref){
                var row = ref.row;
return [_vm._v(" "+_vm._s(row.employee ? row.employee.card_id : '')+" ")]}},{key:"employee_name_en",fn:function(ref){
                var row = ref.row;
return [_vm._v(" "+_vm._s(row.employee ? row.employee.employee_name_en : '')+"| "+_vm._s(row.employee ? row.employee.employee_name_kh : '')+" ")]}},{key:"leave_type_en",fn:function(ref){
                var row = ref.row;
return [_vm._v(" "+_vm._s(row.leave_type ? row.leave_type.leave_type_en : '')+"| "+_vm._s(row.leave_type ? row.leave_type.leave_type_kh : '')+" ")]}}])})],1),(_vm.loading)?_c('Spin',{attrs:{"fix":""}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }