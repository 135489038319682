<template>
    <div>
        <ts-page-title
            :title="$t('monthlyDeduction.pageTitle')"
            :breadcrumb="[
                { text: $t('home'), href: '/' },
                {
                    text: $t('monthlyDeduction.pageTitle'),
                    active: true
                }
            ]"
        />
        <ts-panel>
            <ts-panel-wrapper
                class="tw-flex tw-items-center tw-justify-between"
            >
                <div class="tw-space-x-2">
                    <Button type="info" ghost @click="addNew">{{
                        $t("addNew")
                    }}</Button>
                    <div class="btn-group">
                        <Dropdown trigger="click" class="btn btn-default">
                            <a>
                                Import
                                <Icon type="ios-arrow-down"></Icon>
                            </a>
                            <DropdownMenu slot="list">
                                <DropdownItem
                                    class="tw-text-left"
                                    v-for="(item, index) in payItemDeductions"
                                    :key="index"
                                >
                                    <a
                                        @click.prevent="onImportDeduction(item)"
                                        >{{ item.deduction_item }}</a
                                    >
                                </DropdownItem>
                            </DropdownMenu>
                        </Dropdown>
                    </div>
                    <ts-button
                        color="success"
                        @click.prevent="clearInstallment"
                        >{{
                            $t("monthlyDeduction.clearInstallment")
                        }}</ts-button
                    >
                </div>
                <div class="tw-flex tw-space-x-2">
                    <div>
                        <DatePicker
                            type="year"
                            format="yyyy"
                            placeholder="Select Year"
                            @on-change="onChangeYear"
                            :value="year"
                        ></DatePicker>
                    </div>
                    <div>
                        <DatePicker
                            type="month"
                            format="MM"
                            placeholder="Select Month"
                            @on-change="onChangeMonth"
                            :value="month"
                        >
                        </DatePicker>
                    </div>
                    <div>
                        <Input
                            v-model="search"
                            search
                            :placeholder="$t('monthlyDeduction.searchEmployee')"
                        />
                    </div>
                </div>
            </ts-panel-wrapper>
            <Table
                :columns="columns"
                :data="resources"
                size="small"
                :loading="loading"
                max-height="700"
                stripe
            >
                <template slot-scope="{ row }" slot="card_id">
                    {{
                        row.employee_profile ? row.employee_profile.card_id : ""
                    }}
                </template>
                <template slot-scope="{ row }" slot="employee_name_en">
                    {{
                        row.employee_profile
                            ? $root.$i18n.locale === "kh"
                                ? row.employee_profile.employee_name_kh
                                : row.employee_profile.employee_name_en
                            : ""
                    }}
                </template>
                <template slot-scope="{ row }" slot="sex">
                    {{ row.employee_profile ? row.employee_profile.sex : "" }}
                </template>
                <template slot-scope="{ row }" slot="position_name">
                    {{
                        row.employee_profile
                            ? row.employee_profile.logical_position
                                ? row.employee_profile.logical_position
                                      .position_name_en
                                : ""
                            : ""
                    }}
                </template>
                <template slot-scope="{ row }" slot="deduction_item">
                    {{
                        row.pay_item_deduction
                            ? row.pay_item_deduction.deduction_item
                            : ""
                    }}
                </template>
                <template slot-scope="{ row }" slot="deduction">
                    <span class="tw-font-bold text-blue">{{
                        formatNumber(row.deduction)
                    }}</span>
                </template>
                <template slot-scope="{ row }" slot="is_locked">
                    <span
                        class="badge bg-success"
                        v-if="row.is_locked"
                        >{{ $t("yes") }}</span
                    >
                    <span v-else class="badge bg-secondary">{{
                        $t("no")
                    }}</span>
                </template>
                <template slot-scope="{ row }" slot="action">
                    <a
                        href="#"
                        class="text-primary ml-2"
                        @click.prevent="onEdit(row)"
                        v-tooltip="$t('edit')"
                    >
                        <Icon type="ios-create" size="20" />
                    </a>
                    <Poptip
                        confirm
                        :title="$t('areYouSure')"
                        @on-ok="onDelete(row)"
                        :transfer="true"
                        :ok-text="$t('yes')"
                        :cancel-text="$t('no')"
                    >
                        <a
                            class="ml-2 text-danger"
                            v-tooltip="$t('delete')"
                            :disabled="row._deleting"
                        >
                            <i
                                class="fas fa-circle-notch fa-spin"
                                v-if="row._deleting"
                            ></i>
                            <Icon type="ios-trash" size="20" v-else />
                        </a>
                    </Poptip>
                </template>
            </Table>
            <div class="tw-flex tw-items-center tw-justify-end tw-p-4">
                <ts-i-pagination
                    v-model="pagination"
                    @navigate="fetchData({
                        filter: {
                            search: search,
                            cycle_year: year,
                            cycle_month: month
                        }
                    })"
                ></ts-i-pagination>
            </div>
            <Modal
                v-model="showForm"
                draggable
                sticky
                scrollable
                :mask="false"
                :footer-hide="true"
                :z-index="1022"
                width="700px"
                :title="$t('monthlyDeduction.pageTitle')"
            >
                <from-action
                    ref="form_action"
                    @cancel="clearEdit"
                    @fetchData="fetchData({
                        filter: {
                            search: search,
                            cycle_year: year,
                            cycle_month: month
                        }
                    })"
                />
            </Modal>
            <Modal
                v-model="showUnpaidLeave"
                draggable
                sticky
                scrollable
                :mask="false"
                :footer-hide="true"
                :z-index="1052"
                width="1200px"
                :title="$t('monthlyDeduction.importUpadidLeave')"
            >
                <unpaid-leave
                    ref="unpaid_leave"
                    @importSuccess="unpaidLeaveSuccess"
                />
            </Modal>
            <Modal
                v-model="showInstallment"
                draggable
                sticky
                scrollable
                :mask="false"
                :footer-hide="true"
                :z-index="1052"
                width="1200px"
                :title="$t('monthlyDeduction.importInstallment')"
            >
                <installment
                    ref="installment"
                    @importSuccess="loanInstallmentSuccess"
                />
            </Modal>
            <Modal
                v-model="clearInstallmentForm"
                draggable
                sticky
                scrollable
                :mask="false"
                :footer-hide="true"
                :z-index="1052"
                width="1200px"
                :title="$t('monthlyDeduction.clearLoanInstallment')"
            >
                <clear-installment
                    ref="clear_Installment"
                    @clearSuccess="clearLoanInstallmentSuccess"
                    @close="() => (clearInstallmentForm = false)"
                />
            </Modal>
        </ts-panel>
    </div>
</template>
<script>
import FromAction from "./form.vue";
import UnpaidLeave from "./unpaid-leave.vue";
import Installment from "./installment.vue";
import ClearInstallment from "./clear-installment.vue";
import { mapActions, mapGetters, mapState } from "vuex";
import { debounce } from "lodash";
import moment from "moment";
export default {
    name: "monthly-deduction",
    components: {
        FromAction,
        UnpaidLeave,
        Installment,
        ClearInstallment
    },
    data() {
        return {
            loading: false,
            width: "width: 300px",
            showForm: false,
            clearInstallmentForm: false,
            showUnpaidLeave: false,
            showInstallment: false,
            year: moment().format("YYYY"),
            month: moment().format("MM")
        };
    },
    computed: {
        ...mapState("payroll/monthlyDeduction", ["resources", "pagination"]),
        ...mapGetters(["formatNumber"]),
        search: {
            get() {
                return this.$store.state.payroll.monthlyDeduction.search;
            },
            set(newValue) {
                this.$store.commit(
                    "payroll/monthlyDeduction/SET_SEARCH",
                    newValue
                );
                this.$store.commit(
                    "payroll/monthlyDeduction/RESET_CURRENT_PAGE"
                );
            }
        },
        columns() {
            return [
                {
                    title: this.$t("monthlyDeduction.cardId"),
                    slot: "card_id",
                    sortable: true,
                    minWidth: 100
                },
                {
                    title: this.$t("monthlyDeduction.employeeName"),
                    slot: "employee_name_en",
                    sortable: true,
                    minWidth: 200
                },
                {
                    title: this.$t("monthlyDeduction.sex"),
                    slot: "sex",
                    sortable: true,
                    minWidth: 80
                },
                {
                    title: this.$t("monthlyDeduction.positionName"),
                    slot: "position_name",
                    sortable: true,
                    minWidth: 200
                },
                {
                    title: this.$t("monthlyDeduction.deduction"),
                    slot: "deduction_item",
                    sortable: true,
                    minWidth: 150
                },
                {
                    title: this.$t("monthlyDeduction.deductionAmount"),
                    slot: "deduction",
                    sortable: true,
                    minWidth: 160,
                    align: "center"
                },
                {
                    title: this.$t("monthlyDeduction.cyclePaymentDate"),
                    key: "cycle_payment_date",
                    sortable: true,
                    minWidth: 200
                },
                {
                    title: this.$t("monthlyDeduction.description"),
                    key: "description",
                    sortable: true,
                    minWidth: 200
                },
                {
                    title: this.$t("monthlyDeduction.isClearInstallment"),
                    slot: "is_locked",
                    align: "center",
                    fixed: "right",
                    width: 160
                },
                {
                    title: this.$t("actions"),
                    key: "action",
                    slot: "action",
                    align: "center",
                    fixed: "right",
                    className: "tw-space-x-2",
                    width: 90
                }
            ];
        },
        payItemDeductions() {
            return this.$store.state.payroll.monthlyDeduction.payItemDeductions;
        }
    },
    methods: {
        ...mapActions("payroll/monthlyDeduction", ["getPayItemDeduction"]),
        addNew() {
            this.showForm = true;
            this.$refs.form_action.fetchResource();
            this.$refs.form_action.clearInput();
        },
        clearInstallment() {
            this.clearInstallmentForm = true;
            this.$refs.clear_Installment.fetchResource();
        },
        onImportDeduction(item) {
            if (item.custom_keyword == "UPL") {
                //Unpaid leave
                this.showUnpaidLeave = true;
                this.$refs.unpaid_leave.fetchResource();
                this.$refs.unpaid_leave.model.deduction_id = item.deduction_id;
            }
            if (item.custom_keyword == "INT") {
                // loan installment
                this.showInstallment = true;
                this.$refs.installment.fetchResource();
                this.$refs.installment.model.deduction_id = item.deduction_id;
            }
        },
        clearEdit() {
            this.showForm = false;
            this.$store.commit("payroll/monthlyDeduction/SET_EDIT_DATA", {});
            this.$refs.form_action.clearInput();
        },
        unpaidLeaveSuccess() {
            this.showInstallment = false;
            this.fetchData({
                filter: {
                    search: this.search,
                    cycle_year: this.year,
                    cycle_month: this.month
                }
            });
        },
        loanInstallmentSuccess() {
            this.showInstallment = false;
            this.fetchData({
                filter: {
                    search: this.search,
                    cycle_year: this.year,
                    cycle_month: this.month
                }
            });
        },
        clearLoanInstallmentSuccess() {
            this.clearInstallmentForm = false;
            this.fetchData({
                filter: {
                    search: this.search,
                    cycle_year: this.year,
                    cycle_month: this.month
                }
            });
        },
        fetchData(attributes) {
            this.loading = true;
            this.$store
                .dispatch("payroll/monthlyDeduction/fetch", attributes)
                .catch(error => {
                    this.loading = false;
                    this.notice({
                        type: "error",
                        text: error.message
                    });
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        onEdit(record) {
            this.showForm = true;
            this.$store.commit(
                "payroll/monthlyDeduction/SET_EDIT_DATA",
                record
            );
            this.$refs.form_action.setEditData();
            this.$refs.form_action.fetchResource();
        },
        onDelete(record) {
            record._deleting = true;
            this.$store
                .dispatch("payroll/monthlyDeduction/destroy", record.record_id)
                .then(response => {
                    this.fetchData({
                        filter: {
                            cycle_month: this.month,
                            cycle_year: this.year
                        }
                    });
                    record._deleting = false;
                    this.notice({
                        type: response.message_id == 0 ? "success" : "warning",
                        text: response.message
                    });
                })
                .catch(error => {
                    this.notice({ type: "error", text: error.message });
                    record._deleting = false;
                });
        },
        onChangeYear(year) {
            this.year = year;
            this.fetchData({
                filter: {
                    search: this.search,
                    cycle_year: year,
                    cycle_month: this.month
                }
            });
        },
        onChangeMonth(month) {
            this.month = month;
            this.fetchData({
                filter: {
                    search: this.search,
                    cycle_month: month,
                    cycle_year: this.year
                }
            });
        },
        notice(not) {
            this.$Notice[not.type]({
                title: "MONTHLY DEDUCTION",
                desc: not.text
            });
        }
    },
    watch: {
        search: debounce(function(search) {
            this.fetchData({
                filter: {
                    search: search,
                    cycle_year: this.year,
                    cycle_month: this.month
                }
            });
        }, 500)
    },
    beforeRouteEnter(to, from, next) {
        next(vm => {
            vm.getPayItemDeduction();
            vm.fetchData({
                filter: {
                    search: vm.search,
                    cycle_year: vm.year,
                    cycle_month: vm.month
                }
            });
        });
    }
};
</script>
