<template>
    <div>
        <div class="demo-spin-article">
            <div class="mb-3">
                <label class="col-label form-label required tw-text-xs">{{
                    $t('monthlyDeduction.employee')
                }}</label>
                <div>
                    <Select
                        v-model="model.employee_id"
                        filterable
                        :remote-method="loadEmployee"
                        :clearable="true"
                        :class="{
                            'is-invalid': errors.has('employee_id')
                        }"
                    >
                        <Option
                            v-for="(employeeProfile, index) in employeeProfiles"
                            :value="employeeProfile.employee_id"
                            :key="index"
                            :label="employeeProfile.employee_name_en"
                            >{{ employeeProfile.employee_name_en }}|{{
                                employeeProfile.employee_name_kh
                            }}
                        </Option>
                    </Select>
                    <div
                        class="invalid-feedback"
                        v-if="errors.has('employee_id')"
                    >
                        {{ errors.first('employee_id') }}
                    </div>
                </div>
            </div>
            <div class="row mb-3">
                <div class="col-md-6">
                    <label class="col-label form-label required tw-text-xs">{{
                        $t('monthlyDeduction.deduction')
                    }}</label>
                    <div>
                        <Select
                            v-model="model.deduction_id"
                            :class="{
                                'is-invalid': errors.has('deduction_id')
                            }"
                        >
                            <Option
                                v-for="(payItemDeduction,
                                index) in payItemDeductions"
                                :value="payItemDeduction.deduction_id"
                                :key="index"
                                :label="payItemDeduction.deduction_item"
                                >{{ payItemDeduction.deduction_item }}
                            </Option>
                        </Select>
                        <div
                            class="invalid-feedback"
                            v-if="errors.has('deduction_id')"
                        >
                            {{ errors.first('deduction_id') }}
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <label class="col-label form-label required tw-text-xs">{{
                        $t('monthlyDeduction.deductionAmount')
                    }}</label>
                    <div class="tw-whitespace-nowrap">
                        <Poptip trigger="focus">
                            <Input
                                v-model.number="model.deduction"
                                type="number"
                                :placeholder="
                                    $t('loanDisbursement.enterNumber')
                                "
                                style="width: 172%"
                                ref="deduction"
                                :class="{
                                    'is-invalid': errors.has('deduction')
                                }"
                            >
                            </Input>
                            <div
                                class="invalid-feedback"
                                v-if="errors.has('deduction')"
                            >
                                {{ errors.first('deduction') }}
                            </div>
                            <div slot="content">
                                {{
                                    model.deduction
                                        ? formatNumber(model.deduction)
                                        : $t('loanDisbursement.enterNumber')
                                }}
                            </div>
                        </Poptip>
                    </div>
                </div>
            </div>
            <div class="row mb-3">
                <div class="col-md-6">
                    <label class="col-label form-label required tw-text-xs">{{
                        $t('monthlyDeduction.cycleYear')
                    }}</label>
                    <div>
                        <date-Picker
                            :value="model.cycle_year"
                            type="year"
                            format="yyyy"
                            placeholder="Select Year"
                            style="width:320px"
                            @on-change="cycleYearChange"
                            :class="{
                                'is-invalid': errors.has('cycle_year')
                            }"
                        >
                        </date-Picker>
                        <div
                            class="invalid-feedback"
                            v-if="errors.has('cycle_year')"
                        >
                            {{ errors.first('cycle_year') }}
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <label class="col-label form-label required tw-text-xs">{{
                        $t('monthlyDeduction.cycleMonth')
                    }}</label>
                    <div>
                        <date-Picker
                            :value="model.cycle_month"
                            type="month"
                            placeholder="Select Month"
                            style="width:320px"
                            format="MM"
                            @on-change="cycleMonthChange"
                            :class="{
                                'is-invalid': errors.has('cycle_month')
                            }"
                        >
                        </date-Picker>
                        <div
                            class="invalid-feedback"
                            v-if="errors.has('cycle_month')"
                        >
                            {{ errors.first('cycle_month') }}
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <label class="col-label form-label  tw-text-xs">{{
                    $t('monthlyDeduction.description')
                }}</label>
                <Input
                    v-model="model.description"
                    type="textarea"
                    :rows="5"
                    placeholder="Enter something..."
                />
            </div>
        </div>
        <div slot="footer">
            <div class="tw-flex tw-justify-end tw-space-x-2 tw-sh mt-3">
                <ts-button
                    class="btn-gray"
                    @click.prevent="() => $emit('cancel')"
                >
                    {{ $t('cancel') }}</ts-button
                >
                <ts-button
                    v-if="!isUpdate"
                    color="primary"
                    outline
                    :waiting="waiting_new"
                    :disabled="waiting_new || waiting"
                    @click.prevent="onSaveAddNew"
                >
                    {{ $t('saveAddNew') }}</ts-button
                >
                <ts-button
                    v-if="!isUpdate"
                    color="primary"
                    :waiting="waiting"
                    :disabled="waiting_new || waiting"
                    @click.prevent="onSave"
                >
                    {{ $t('save') }}</ts-button
                >
                <ts-button
                    v-if="isUpdate"
                    color="primary"
                    @click.prevent="onUpdate"
                    :waiting="waiting"
                >
                    {{ $t('update') }}</ts-button
                >
            </div>
        </div>
        <Spin fix v-if="loading"></Spin>
    </div>
</template>
<script>
import { Errors } from 'form-backend-validation'
import { debounce } from 'lodash'
import { mapActions, mapState, mapGetters } from 'vuex'
import { isEmpty } from 'lodash'
import moment from 'moment'

export default {
    name: 'monthly-deduction-create',
    data () {
        return {
            errors: new Errors(),
            loading: false,
            waiting: false,
            waiting_new: false,
            model: {
                employee_id: null,
                deduction_id: null,
                deduction: null,
                cycle_year: moment().format('YYYY'),
                cycle_month: moment().format('MM'),
                description: null
            }
        }
    },
    computed: {
        ...mapGetters(['formatNumber']),
        ...mapState('payroll/monthlyDeduction', ['edit_data', 'deductions']),
        isUpdate () {
            return !isEmpty(this.edit_data)
        },
        employeeProfiles () {
            return this.$store.state.payroll.monthlyDeduction.employeeProfiles
        },
        payItemDeductions () {
            return this.$store.state.payroll.monthlyDeduction.payItemDeductions
        }
    },
    methods: {
        ...mapActions('payroll/monthlyDeduction', [
            'getEmployeeProfile',
            'getPayItemDeduction'
        ]),
        fetchResource () {
            this.loading = true
            this.getEmployeeProfile()
            this.getPayItemDeduction()
                .then(() => {})
                .catch(error => {
                    this.notice({ type: 'error', text: error.message })
                })
                .finally(() => {
                    this.loading = false
                })
        },
        loadEmployee: debounce(function (query) {
            this.getEmployeeProfile({ search: query })
        }, 1000),
        cycleYearChange (date) {
            this.model.cycle_year = date
        },
        cycleMonthChange (value) {
            this.model.cycle_month = value
        },
        onSave () {
            this.errors = new Errors()
            this.waiting = true
            this.$store
                .dispatch('payroll/monthlyDeduction/store', this.model)
                .then(response => {
                    this.notice({
                        type: response.message_id == 0 ? 'success' : 'warning',
                        text: response.message
                    })
                    this.$emit('ceacel')
                    this.$emit('fetchData')
                })
                .catch(error => {
                    this.errors = new Errors(error.errors)
                    this.notice({
                        type: 'error',
                        text: error.message
                    })
                })
                .finally(() => {
                    this.waiting = false
                })
        },
        async onSaveAddNew () {
            this.errors = new Errors()
            this.waiting_new = true
            this.$store
                .dispatch('payroll/monthlyDeduction/store', this.model)
                .then(response => {
                    this.notice({
                        type: response.message_id == 0 ? 'success' : 'warning',
                        text: response.message
                    })
                    this.$emit('fetchData')
                    this.clearInput()
                    this.fetchResource()
                })
                .catch(error => {
                    this.errors = new Errors(error.errors)
                    this.notice({ type: 'error', text: error.message })
                })
                .finally(() => {
                    this.waiting_new = false
                })
        },
        async setEditData () {
            if (!isEmpty(this.edit_data)) {
                await this.getEmployeeProfile({
                    search: this.edit_data.employee_profile.employee_name_en
                })
                this.model.employee_id = this.edit_data.employee_id
                this.model.deduction_id = this.edit_data.deduction_id
                this.model.deduction = this.edit_data.deduction
                this.model.cycle_year = this.edit_data.cycle_year
                this.model.cycle_month = this.edit_data.cycle_month
                this.model.description = this.edit_data.description
            }
        },
        clearInput () {
            this.errors = new Errors()
            this.model.employee_id = null
            this.model.deduction_id = null
            this.model.deduction = null
            ;(this.model.cycle_year = moment().format('YYYY')),
                (this.model.cycle_month = moment().format('MM')),
                (this.model.description = null)
        },
        onUpdate () {
            this.waiting = true
            this.$store
                .dispatch('payroll/monthlyDeduction/update', {
                    id: this.edit_data.record_id,
                    data: this.model
                })
                .then(response => {
                    this.notice({
                        type: response.message_id == 0 ? 'success' : 'warning',
                        text: response.message
                    })
                    this.$emit('cancel')
                    this.$emit('fetchData')
                })
                .catch(error => {
                    this.errors = new Errors(error.errors)
                    this.notice({
                        type: 'error',
                        text: error.message
                    })
                })
                .finally(() => {
                    this.waiting = false
                })
        },
        notice (not) {
            this.$Notice[not.type]({
                title: 'MONTHLY DEDUCTION',
                desc: not.text
            })
        }
    },
    watch: {
        search: debounce(function (search) {
            this.fetchData({ search })
        }, 500)
    }
}
</script>
