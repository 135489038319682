var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"demo-spin-article"},[_c('div',{staticClass:"mb-3"},[_c('label',{staticClass:"col-label form-label required tw-text-xs"},[_vm._v(_vm._s(_vm.$t('monthlyDeduction.employee')))]),_c('div',[_c('Select',{class:{
                        'is-invalid': _vm.errors.has('employee_id')
                    },attrs:{"filterable":"","remote-method":_vm.loadEmployee,"clearable":true},model:{value:(_vm.model.employee_id),callback:function ($$v) {_vm.$set(_vm.model, "employee_id", $$v)},expression:"model.employee_id"}},_vm._l((_vm.employeeProfiles),function(employeeProfile,index){return _c('Option',{key:index,attrs:{"value":employeeProfile.employee_id,"label":employeeProfile.employee_name_en}},[_vm._v(_vm._s(employeeProfile.employee_name_en)+"|"+_vm._s(employeeProfile.employee_name_kh)+" ")])}),1),(_vm.errors.has('employee_id'))?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(_vm.errors.first('employee_id'))+" ")]):_vm._e()],1)]),_c('div',{staticClass:"row mb-3"},[_c('div',{staticClass:"col-md-6"},[_c('label',{staticClass:"col-label form-label required tw-text-xs"},[_vm._v(_vm._s(_vm.$t('monthlyDeduction.deduction')))]),_c('div',[_c('Select',{class:{
                            'is-invalid': _vm.errors.has('deduction_id')
                        },model:{value:(_vm.model.deduction_id),callback:function ($$v) {_vm.$set(_vm.model, "deduction_id", $$v)},expression:"model.deduction_id"}},_vm._l((_vm.payItemDeductions),function(payItemDeduction,index){return _c('Option',{key:index,attrs:{"value":payItemDeduction.deduction_id,"label":payItemDeduction.deduction_item}},[_vm._v(_vm._s(payItemDeduction.deduction_item)+" ")])}),1),(_vm.errors.has('deduction_id'))?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(_vm.errors.first('deduction_id'))+" ")]):_vm._e()],1)]),_c('div',{staticClass:"col-md-6"},[_c('label',{staticClass:"col-label form-label required tw-text-xs"},[_vm._v(_vm._s(_vm.$t('monthlyDeduction.deductionAmount')))]),_c('div',{staticClass:"tw-whitespace-nowrap"},[_c('Poptip',{attrs:{"trigger":"focus"}},[_c('Input',{ref:"deduction",class:{
                                'is-invalid': _vm.errors.has('deduction')
                            },staticStyle:{"width":"172%"},attrs:{"type":"number","placeholder":_vm.$t('loanDisbursement.enterNumber')},model:{value:(_vm.model.deduction),callback:function ($$v) {_vm.$set(_vm.model, "deduction", _vm._n($$v))},expression:"model.deduction"}}),(_vm.errors.has('deduction'))?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(_vm.errors.first('deduction'))+" ")]):_vm._e(),_c('div',{attrs:{"slot":"content"},slot:"content"},[_vm._v(" "+_vm._s(_vm.model.deduction ? _vm.formatNumber(_vm.model.deduction) : _vm.$t('loanDisbursement.enterNumber'))+" ")])],1)],1)])]),_c('div',{staticClass:"row mb-3"},[_c('div',{staticClass:"col-md-6"},[_c('label',{staticClass:"col-label form-label required tw-text-xs"},[_vm._v(_vm._s(_vm.$t('monthlyDeduction.cycleYear')))]),_c('div',[_c('date-Picker',{class:{
                            'is-invalid': _vm.errors.has('cycle_year')
                        },staticStyle:{"width":"320px"},attrs:{"value":_vm.model.cycle_year,"type":"year","format":"yyyy","placeholder":"Select Year"},on:{"on-change":_vm.cycleYearChange}}),(_vm.errors.has('cycle_year'))?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(_vm.errors.first('cycle_year'))+" ")]):_vm._e()],1)]),_c('div',{staticClass:"col-md-6"},[_c('label',{staticClass:"col-label form-label required tw-text-xs"},[_vm._v(_vm._s(_vm.$t('monthlyDeduction.cycleMonth')))]),_c('div',[_c('date-Picker',{class:{
                            'is-invalid': _vm.errors.has('cycle_month')
                        },staticStyle:{"width":"320px"},attrs:{"value":_vm.model.cycle_month,"type":"month","placeholder":"Select Month","format":"MM"},on:{"on-change":_vm.cycleMonthChange}}),(_vm.errors.has('cycle_month'))?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(_vm.errors.first('cycle_month'))+" ")]):_vm._e()],1)])]),_c('div',[_c('label',{staticClass:"col-label form-label  tw-text-xs"},[_vm._v(_vm._s(_vm.$t('monthlyDeduction.description')))]),_c('Input',{attrs:{"type":"textarea","rows":5,"placeholder":"Enter something..."},model:{value:(_vm.model.description),callback:function ($$v) {_vm.$set(_vm.model, "description", $$v)},expression:"model.description"}})],1)]),_c('div',{attrs:{"slot":"footer"},slot:"footer"},[_c('div',{staticClass:"tw-flex tw-justify-end tw-space-x-2 tw-sh mt-3"},[_c('ts-button',{staticClass:"btn-gray",on:{"click":function($event){$event.preventDefault();return (function () { return _vm.$emit('cancel'); }).apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.$t('cancel')))]),(!_vm.isUpdate)?_c('ts-button',{attrs:{"color":"primary","outline":"","waiting":_vm.waiting_new,"disabled":_vm.waiting_new || _vm.waiting},on:{"click":function($event){$event.preventDefault();return _vm.onSaveAddNew.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.$t('saveAddNew')))]):_vm._e(),(!_vm.isUpdate)?_c('ts-button',{attrs:{"color":"primary","waiting":_vm.waiting,"disabled":_vm.waiting_new || _vm.waiting},on:{"click":function($event){$event.preventDefault();return _vm.onSave.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.$t('save')))]):_vm._e(),(_vm.isUpdate)?_c('ts-button',{attrs:{"color":"primary","waiting":_vm.waiting},on:{"click":function($event){$event.preventDefault();return _vm.onUpdate.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.$t('update')))]):_vm._e()],1)]),(_vm.loading)?_c('Spin',{attrs:{"fix":""}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }