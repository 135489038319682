var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"mb-3 demo-spin-article"},[_c('ts-panel-wrapper',{staticClass:"tw-flex tw-items-center tw-justify-between"},[_c('div',{staticClass:" tw-flex tw-ml-12"},[_c('div',{staticClass:"tw-space-x-4"},[_c('label',{staticClass:"col-label form-label tw-text-xs"},[_vm._v(_vm._s(_vm.$t("monthlyDeduction.year")))]),_c('date-Picker',{class:{
                            'is-invalid': _vm.errors.has('cycle_year')
                        },staticStyle:{"width":"240px"},attrs:{"value":_vm.model.cycle_year,"type":"year","format":"yyyy","placeholder":"Select Year"},on:{"on-change":_vm.cycleYearChange}}),(_vm.errors.has('cycle_year'))?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(_vm.errors.first("cycle_year"))+" ")]):_vm._e()],1),_c('div',{staticClass:" tw-space-x-4"},[_c('label',{staticClass:"col-label form-label tw-text-xs tw-ml-12"},[_vm._v(_vm._s(_vm.$t("monthlyDeduction.month")))]),_c('DatePicker',{class:{
                            'is-invalid': _vm.errors.has('cycle_month')
                        },staticStyle:{"width":"240px"},attrs:{"value":_vm.model.cycle_month,"type":"month","placeholder":"Select Month","format":"MM"},on:{"on-change":_vm.cycleMonthChange}}),(_vm.errors.has('cycle_month'))?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(_vm.errors.first("cycle_month"))+" ")]):_vm._e()],1),_c('div',{staticClass:" tw-space-x-4 tw-flex"},[_c('label',{staticClass:"col-label form-label tw-text-xs tw-ml-12 tw-mt-2"},[_vm._v(" "+_vm._s(_vm.$t("monthlyDeduction.cycleOrder"))+" ")]),_c('Select',{staticStyle:{"width":"240px"},on:{"on-change":_vm.cycleOption},model:{value:(_vm.model.cycle_no),callback:function ($$v) {_vm.$set(_vm.model, "cycle_no", $$v)},expression:"model.cycle_no"}},_vm._l((_vm.cycleOrders),function(c){return _c('Option',{key:c.value,attrs:{"value":c.value}},[_vm._v(_vm._s(c.text)+" ")])}),1)],1)]),_c('ts-button',{attrs:{"color":"primary","waiting":_vm.waiting,"disabled":_vm.waiting},on:{"click":function($event){$event.preventDefault();return _vm.onGenerate.apply(null, arguments)}}},[_vm._v(" Submit ")])],1),_c('div',{staticClass:"mb-3"},[_c('Input',{attrs:{"search":"","placeholder":_vm.$t('monthlyDeduction.search')},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('Table',{ref:"selection",attrs:{"highlight-row":"","columns":_vm.columns,"data":_vm.employees,"max-height":"500","size":"small","stripe":""},on:{"on-select":_vm.setEmployeeIdListSelected,"on-select-cancel":_vm.setEmployeeIdListSelected,"on-select-all":_vm.setEmployeeIdListSelected,"on-select-all-cancel":_vm.setEmployeeIdListSelected},scopedSlots:_vm._u([{key:"photo",fn:function(ref){
                        var row = ref.row;
return [_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:({
                        src: _vm.photo(row),
                        error:
                            row.sex == 'Male'
                                ? require('@/assets/staff-default-man.png')
                                : require('@/assets/staff-default-women.png'),
                        loading: require('@/assets/Rolling-1s-200px.svg'),
                        preLoad: 1.3,
                        attempt: 1
                    }),expression:"{\n                        src: photo(row),\n                        error:\n                            row.sex == 'Male'\n                                ? require('@/assets/staff-default-man.png')\n                                : require('@/assets/staff-default-women.png'),\n                        loading: require('@/assets/Rolling-1s-200px.svg'),\n                        preLoad: 1.3,\n                        attempt: 1\n                    }"}],staticClass:"tw-rounded tw-h-8"})]}},{key:"employee_name",fn:function(ref){
                    var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.locale == "kh" ? row.employee_name_kh : row.employee_name_en)+" ")]}},{key:"installment_amount",fn:function(ref){
                    var row = ref.row;
return [_c('span',{staticClass:"tw-font-bold text-blue"},[_vm._v(_vm._s(_vm.formatNumber(row.installment_amount, row.currency_id) + " " + _vm.signBycurrencyId(row.currency_id)))])]}},{key:"outstanding_amount",fn:function(ref){
                    var row = ref.row;
return [_c('span',{staticClass:"tw-font-bold text-warning"},[_vm._v(_vm._s(_vm.formatNumber(row.outstanding_amount, row.currency_id) + " " + _vm.signBycurrencyId(row.currency_id)))])]}}])}),(_vm.errors.has('object_detail'))?_c('div',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(_vm.errors.first("object_detail"))+" ")]):_vm._e()],1),(_vm.loading)?_c('Spin',{attrs:{"fix":""}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }